<template>
  <div class="code-wrap" :class="{ 'is-blinked': blink }">
    <div :id="codeId" ref="codeRef" class="code" />
    <div class="icon-space">
      <div v-if="isSwitch" class="icon-wrap-switch">
        <AppIcon name="switch-logo" size="100%" is-img-tag />
      </div>

      <div v-else class="icon-wrap">
        <FamilyIcon
          v-if="familyIcon"
          :family-icon="familyIcon"
          :icon="icon"
          size="32px"
        />
        <AppIcon
          v-else
          :name="icon"
          is-img-tag
          size="100%"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed, onMounted, ref, watch,
} from 'vue';
import QRCodeStyling from 'qr-code-styling';

import FamilyIcon from '@/components/Icon/FamilyIcon.vue';

export default {
  name: 'QRcode',
  components: {
    FamilyIcon,
  },
  props: {
    data: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    familyIcon: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '190px',
    },
    bgColor: {
      type: String,
      default: 'transparent',
    },
    type: {
      type: String,
      default: 'svg',
    },
    isSwitch: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['load'],
  setup(props, { emit }) {
    const baseOptions = {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
        color1: '#13171A',
        color2: '#13171A',
        rotation: '0',
      },
    };
    const codeId = (Math.random() + 1).toString(36).substring(7);

    const baseSize = 320;

    const options = computed(() => ({
      width: baseSize,
      height: baseSize,
      data: encodeURI(props.data) || '',
      type: props.type,
      margin: 0,
      qrOptions: {
        typeNumber: '0',
        mode: 'Byte',
        errorCorrectionLevel: 'M',
      },
      imageOptions: {
        hideBackgroundDots: false,
        imageSize: 0.5,
        margin: 8,
      },
      dotsOptions: {
        type: 'dots',
        color: '#13171A',
      },
      backgroundOptions: {
        color: props.bgColor,
      },
      dotsOptionsHelper: baseOptions,
      cornersSquareOptions: {
        type: 'dot',
        color: '#13171A',
      },
      cornersSquareOptionsHelper: baseOptions,
      cornersDotOptions: {
        type: 'dot',
        color: '#13171A',
      },
      cornersDotOptionsHelper: baseOptions,
      backgroundOptionsHelper: baseOptions,
    }));

    const setSVGattributes = (el, width, height) => {
      const svg = el.querySelector('svg');

      if (svg) {
        svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
        svg.setAttribute('width', '100%');
        svg.setAttribute('height', '100%');
      }
    };

    let qrCode = {};
    const codeRef = ref(null);

    const updateQRcomponent = () => {
      qrCode.update(options.value);
      setSVGattributes(codeRef.value, baseSize, baseSize);
    };

    const createQRcomponent = async () => {
      qrCode = new QRCodeStyling(options.value);
      qrCode.append(codeRef.value);
      setSVGattributes(codeRef.value, baseSize, baseSize);
      emit('load');
    };

    onMounted(() => {
      createQRcomponent();
    });

    const blink = ref(false);

    const forceReload = () => {
      blink.value = true;

      setTimeout(() => {
        updateQRcomponent();
      }, 150);

      setTimeout(() => {
        blink.value = false;
      }, 200);
    };

    watch(() => props.data, () => {
      forceReload();
    });

    return {
      codeId, codeRef, blink, forceReload,
    };
  },
};
</script>

<style lang="scss" scoped>
.code-wrap {
  width: v-bind('size');
  height: v-bind('size');
  overflow: hidden;
  position: relative;

  opacity: 1;
  @include transition-base(opacity, 0.15s);

  &.is-blinked {
    opacity: 0;
  }

  .code {
    width: 100%;
    height: 100%;
  }

  .icon-space {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-wrap {
      width: 50px;
      background:var(--color-white);
      border-radius: 50%;
      padding: 5%;

      :deep(.icon) {
        opacity: 1;
      }
    }

    .icon-wrap-switch {
      width: 40%;
      background:var(--color-white);
      aspect-ratio: 42/24;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 9999px;

      :deep(.icon) {
        width: 80%;

        path {
          opacity: 1;
        }
      }
    }
  }
}

:global(.code-wrap .code canvas) {
  width: 100%;
}
</style>
